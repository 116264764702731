@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: font-inter, Helvetica, sans-serif;
}

/*uncommment to debug alignment and overflow issues, add other classes like p, h^ as needed
div, span, section {
  border: 1px dashed red;
}
*/

h1 {
  @apply text-5xl font-raleway font-bold;  
}
h2 {
  @apply text-4xl font-raleway font-bold;
}
h3 {
  @apply text-2xl font-raleway font-bold;
}
h4 {
  @apply text-xl font-raleway font-bold;
}
h5 {
  @apply text-base font-raleway font-semibold;
}
h6 {
  @apply text-sm font-raleway;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

/*
.theme-custom {
  --gradient: #00a0e0;

  --background: 209 65% 3.52%;
  --foreground: 209 10% 97.2%;

  --muted: 209 50% 13.2%;
  --muted-foreground: 209 10% 54.4%;

  --popover: 209 45% 5.72%;
  --popover-foreground: 209 10% 97.2%;

  --card: 209 45% 5.72%;
  --card-foreground: 209 10% 97.2%;

  --border: 209 50% 13.2%;
  --input: 209 50% 13.2%;

  --primary: 209 100% 44%;
  --primary-foreground: 209 10% 97.2%;

  --secondary: 209 50% 13.2%;
  --secondary-foreground: 209 10% 97.2%;

  --accent: 209 50% 13.2%;
  --accent-foreground: 209 10% 97.2%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 209 10% 97.2%;

  --ring: 209 100% 44%;
}
*/

/*

ProxyTwin
	pt-blue primary  #00a0e0   blue      197 100% 44%   
  pt-yellow secondary #ffb71b  yellow   	41 100% 55%
  pt-green okay  #00ae41      green    	#00ae41  - 142 100% 34%
  pt-red destructive red   #ed1c24    #ed1c24 - 	358 85% 52%
  pt-grey        grey - #839598 - 	189 9% 55%
  black - #000000 - 0 0% 0%
  white - #ffffff - 0 0% 100%  
*/

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 20 14.3% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 20 14.3% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 20 14.3% 4.1%;
    --primary: 197 100% 44%;  /* PTBlue  Original ShadCN -> 24 9.8% 10% */
    --primary-foreground: 0 0% 100% ;  /*  60 9.1% 97.8%*/
    --secondary: 41 100% 55%;  /* PTYellow 60 4.8% 95.9% */ 
    --secondary-foreground: 0 0% 0%; /* 24 9.8% 10%  */
    --muted: 60 4.8% 95.9%;
    --muted-foreground: 25 5.3% 44.7%;
    --accent: 197 100% 44%;  /* 60 4.8% 95.9% */
    --accent-foreground: 24 9.8% 10%;
    --destructive: 358 85% 52%; /* PTRed  0 84.2% 60.2% */
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 189 9% 55%;   /*20 5.9% 90%*/
    --input: 189 9% 55%;  /* "" */
    --ring: 20 14.3% 4.1%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.4rem;
    --okay: 142 100% 34%;   /*  PTGreen */
    --okay-foreground: 0 0% 100%;
    --pt-blue: 197 100% 44%;
    --pt-green: 142 100% 34%;
    --pt-yellow: 41 100% 55%;
    --pt-red: 358 85% 52%;
    --pt-grey: 189 9% 55%;

  }
  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 60 9.1% 97.8%;
    --card: 20 14.3% 4.1%;
    --card-foreground: 60 9.1% 97.8%;
    --popover: 20 14.3% 4.1%;
    --popover-foreground: 60 9.1% 97.8%;
    --primary: 60 9.1% 97.8%;
    --primary-foreground: 24 9.8% 10%;
    --secondary: 12 6.5% 15.1%;
    --secondary-foreground: 60 9.1% 97.8%;
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 60 9.1% 97.8%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 12 6.5% 15.1%;
    --input: 12 6.5% 15.1%;
    --ring: 24 5.7% 82.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}


@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

/* Ensures the video container size remains fixed, preventing resizing */
.fixed-size-container {
  width: 100%; 
  height: 400px; 
  position: relative;
  overflow: hidden;
}

.video-mirror {  /* rotate user camera */  
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    -moz-transform:rotateY(180deg); /* Firefox */
}

.video {
  /* transition: opacity 0.5s ease-in-out; */
  width: 100%; /* Keep the width consistent */
  height: 100%; 
}

.hidden {
  display: none;
}



